import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Heading,
  VStack,
  Text,
  Container,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
  Button,
  Flex
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { fetchBatchDetails, downloadBatchSheet } from '../../store/actions/manualPayoutBatchActions';

const BatchDetailsPage = () => {
  const { batchId } = useParams();
  const dispatch = useDispatch();
  const { batchDetails, loading, error } = useSelector((state) => state.manualPayoutBatch);

  useEffect(() => {
    dispatch(fetchBatchDetails(batchId));
  }, [batchId, dispatch]);

  const handleDownloadClick = (batchId) => {
    dispatch(downloadBatchSheet(batchId));
  };
  const cellStyles = {
    borderColor: 'whiteAlpha.300',
    py: 2,
    px: 3,
    fontSize: 'sm',
    whiteSpace: 'nowrap',
  };

  if (loading) {
    return (
      <ContentLayout>
        <Container maxW="container.xl" py={10}>
          <Spinner size="xl" color="brand.500" />
        </Container>
      </ContentLayout>
    );
  }

  if (error) {
    return (
      <ContentLayout>
        <Container maxW="container.xl" py={10}>
          <Text color="red.400">Error: {error}</Text>
        </Container>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout>
      <VStack spacing={4} align="stretch" width="100%">
        <Container maxW="container.xl" py={6} px={4}>
        <Box mb={4}>
          <Flex justify="space-between" align="center">
            <Heading size="lg" color="brand.500">
              Batch Details: {batchId}
            </Heading>
            <Button colorScheme="green" onClick={() => handleDownloadClick(batchId)}>
              Download
            </Button>
          </Flex>
          <Text fontSize="sm" color="gray.400" mt={2}>
            View detailed information for this batch.
          </Text>
        </Box>
          
          <Box
              width="100%"
              overflowX="auto"
              maxHeight="65vh" 
              overflowY="auto"
              sx={{
                '::-webkit-scrollbar': {
                  height: '2px', 
                },
                '::-webkit-scrollbar-thumb': {
                  background: 'brand.500',
                  borderRadius: '4px',
                  marginTop: '4px',  // Adds space above the scrollbar thumb
                },
              }}
            >
            <Table variant="Table" size="md">
            <Thead position="sticky" top={0} bg="gray.900" zIndex={1} borderBottom="1px" borderColor="brand.500">
                <Tr>
                  <Th style={cellStyles} color="gray.400">Row</Th>
                  <Th style={cellStyles} color="gray.400">INR Amount</Th>
                  <Th style={cellStyles} color="gray.400">Account Number</Th>
                  <Th style={cellStyles} color="gray.400">Account Holder</Th>
                  <Th style={cellStyles} color="gray.400">IFSC Code</Th>
                  <Th style={cellStyles} color="gray.400">Remarks</Th>
                  <Th style={cellStyles} color="gray.400">UTR</Th>
                  <Th style={cellStyles} color="gray.400">Vance Order ID</Th>
                  <Th style={cellStyles} color="gray.400">Vance Status</Th>
                  <Th style={cellStyles} color="gray.400">Payout ID</Th>
                </Tr>
              </Thead>
              <Tbody>
                  {batchDetails && Array.isArray(batchDetails) && batchDetails.length > 0 ? (
                    batchDetails
                      .slice() // Make a copy to avoid mutating the original state
                      .sort((a, b) => b.inrAmt - a.inrAmt) // Sort in descending order by INR Amount
                      .map((entry, index) => (
                        <Tr key={entry.payoutId}>
                          <Td style={cellStyles} color="white">{index + 1}</Td>
                          <Td style={cellStyles} color="white">{entry.inrAmt}</Td>
                          <Td style={cellStyles} color="white">{entry.accNumber}</Td>
                          <Td style={cellStyles} color="white">{entry.accHolderName || '-'}</Td>
                          <Td style={cellStyles} color="white">{entry.ifsc}</Td>
                          <Td style={cellStyles} color="white">{entry.comments !== "null" ? entry.comments : '-'}</Td>
                          <Td style={cellStyles} color="white">{entry.externalId !== "null" ? entry.externalId : '-'}</Td>
                          <Td style={cellStyles} color="white">{entry.orderId}</Td>
                          <Td style={cellStyles} color="white">{entry.status}</Td>
                          <Td style={cellStyles} color="white">{entry.payoutId}</Td>
                        </Tr>
                      ))
                  ) : (
                    <Tr>
                      <Td colSpan={10} style={cellStyles} color="white" textAlign="center">No data available for this batch.</Td>
                    </Tr>
                  )}
              </Tbody>
            </Table>
          </Box>
        </Container>
      </VStack>
    </ContentLayout>
  );
};

export default BatchDetailsPage;