import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';
import { RDA_PROVIDERS } from '../../../constants';

const UpdateRdaProviderModal = ({
  isLoading,
  onCancel = () => {},
  onSubmit = () => {},
  provider
}) => {
  const [payload, setPayload] = useState({
    reason: '',
    provider: provider,
    status: provider.status
  });

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit && onSubmit(payload);
  };

  const handleChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setPayload((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ModalHeader>Update RDA Provider - {provider?.provider}</ModalHeader>
        <ModalBody>
          <VStack alignItems={'stretch'} gap={2}>
            <Box>
              <FormLabel></FormLabel>
              <Select
                name="status"
                value={payload?.provider}
                onChange={handleChange}
              >
                {['ACTIVE', 'INACTIVE'].map((provider) => (
                  <option key={provider} value={provider}>
                    {provider}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <FormLabel>Reason</FormLabel>
              <Input
                name="reason"
                value={payload?.reason}
                onChange={handleChange}
              />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              type="button"
              onClick={handleCancel}
              disabled={isLoading}
              colorScheme="red"
              variant={'outline'}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="brand"
              isLoading={isLoading}
              variant={'outline'}
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default UpdateRdaProviderModal;
