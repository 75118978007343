const REQ_DATA = {
  UPDATE_USERS_REQ_DATA: 'UPDATE_USERS_REQ_DATA',
  UPDATE_ORDERS_REQ_DATA: 'UPDATE_ORDERS_REQ_DATA',
  UPDATE_LULU_ORDERS_REQ_DATA: 'UPDATE_LULU_ORDERS_REQ_DATA',
  UPDATE_ACTIVE_SEARCH: 'UPDATE_ACTIVE_SEARCH',
  UPDATE_ACTIVE_ORDERS_FUNNEL: 'UPDATE_ACTIVE_ORDERS_FUNNEL',
  UPDATE_ACTIVE_LULU_ORDERS_TAB: 'UPDATE_ACTIVE_LULU_ORDERS_TAB',
  UPDATE_ACTIVE_KYC_USERS_FUNNEL: 'UPDATE_ACTIVE_KYC_USERS_FUNNEL',
  UPDATE_FUNNEL_ORDERS_REQ_DATA: 'UPDATE_FUNNEL_ORDERS_REQ_DATA',
  UPDATE_LULU_FUNNEL_ORDERS_REQ_DATA: 'UPDATE_LULU_FUNNEL_ORDERS_REQ_DATA',
  UPDATE_FUNNEL_USERS_REQ_DATA: 'UPDATE_FUNNEL_USERS_REQ_DATA',
  UPDATE_FUNNEL_FLAGGED_FILTER: 'UPDATE_FUNNEL_FLAGGED_FILTER',
  UPDATE_CAMPAIGNS_REQ_DATA: 'UPDATE_CAMPAIGNS_REQ_DATA',
  UPDATE_NR_USERS_REQ_DATA: 'UPDATE_NR_USERS_REQ_DATA',
  UPDATE_PROVIDER_HOLIDAYS_REQ_DATA: 'UPDATE_PROVIDER_HOLIDAYS_REQ_DATA',
  UPDATE_KYC_DOCS_REQ_DATA: 'UPDATE_KYC_DOCS_REQ_DATA',
  UPDATE_KYC_CHECKS_REQ_DATA: 'UPDATE_KYC_CHECKS_REQ_DATA',
  UPDATE_ALL_KYCS_REQ_DATA: 'UPDATE_ALL_KYCS_REQ_DATA',
  UPDATE_USER_KYC_REQ_DATA: 'UPDATE_USER_KYC_REQ_DATA',

  UPDATE_USER_TRANSFERS_REQ_DATA: 'UPDATE_USER_TRANSFERS_REQ_DATA',
  UPDATE_USER_INVESTMENT_ORDERS_REQ_DATA:
    'UPDATE_USER_INVESTMENT_ORDERS_REQ_DATA',
  UPDATE_USER_REFERRAL_REQ_DATA: 'UPDATE_USER_REFERRAL_REQ_DATA',
  UPDATE_INVESTMENT_ORDERS_REQ_DATA: 'UPDATE_INVESTMENT_ORDERS_REQ_DATA',
  UPDATE_USER_SIPS_REQ_DATA: 'UPDATE_USER_SIPS_REQ_DATA',

  UPDATE_THUNES_ORDERS_REQ_DATA: 'UPDATE_THUNES_ORDERS_REQ_DATA',
  UPDATE_THUNES_FULFILLMENTS_REQ_DATA: 'UPDATE_THUNES_FULFILLMENTS_REQ_DATA',

  UPDATE_SOURCE_ACCOUNTS_REQ_DATA: 'UPDATE_SOURCE_ACCOUNTS_REQ_DATA',

  UPDATE_JOBS_REQ_DATA: 'UPDATE_JOBS_REQ_DATA',

  UPDATE_REDEEMED_COINS_REQ_DATA: 'UPDATE_REDEEMED_COINS_REQ_DATA',
  UPDATE_USER_REDEEMED_COINS_REQ_DATA: 'UPDATE_USER_REDEEMED_COINS_REQ_DATA',

  UPDATE_FUNNEL_V2_REQ_DATA: 'UPDATE_FUNNEL_V2_REQ_DATA',
  UPDATE_FUNNEL_V2_ORDERS_REQ_DATA: 'UPDATE_FUNNEL_V2_ORDERS_REQ_DATA',

  UPDATE_TICKETS_REQ_DATA: 'UPDATE_TICKETS_REQ_DATA',
  /* nronboard states */
  // UPDATE_NROB_ACTIVE_FUNNEL: 'UPDATE_NROB_ACTIVE_FUNNEL',
  // UPDATE_NROB_QUERY: 'UPDATE_NROB_QUERY'
  UPDATE_NROB_REQ_DATA: 'UPDATE_NROB_REQ_DATA',
  UPDATE_USER_SOURCE_ACCOUNTS_REQ_DATA: 'UPDATE_USER_SOURCE_ACCOUNTS_REQ_DATA',

  UPDATE_RDA_REQ_DATA: 'UPDATE_RDA_REQ_DATA',
  UPDATE_VANCE_CASH_REQ_DATA: 'UPDATE_VANCE_CASH_REQ_DATA',
  UPDATE_VDA_FUNNEL_REQ_DATA: 'UPDATE_VDA_FUNNEL_REQ_DATA',
  UPDATE_UAE_MANUAL_NON_RECONCILED: 'UPDATE_UAE_MANUAL_NON_RECONCILED',
  UPDATE_PENDING_ORDER_RECEIPTS_REQ_DATA:
    'UPDATE_PENDING_ORDER_RECEIPTS_REQ_DATA'
};

export default REQ_DATA;
