import { USERS, RES_DATA } from '../constants';
import api from '../../api';
import { setAlert } from './alertActions';
import { getErrorMessage } from '../../utils';

export const getAllUsers =
  (searchReqData, refresh = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RES_DATA.SEARCHING_USERS,
        payload: {
          pageNo: searchReqData.pageNo,
          refresh
        }
      });

      let { data: usersList } = await api.search.users({ ...searchReqData });

      return dispatch({
        type: RES_DATA.SEARCH_USERS_SUCCESS,
        payload: usersList
      });
    } catch (error) {
      return dispatch({
        type: RES_DATA.SEARCH_USERS_FAILURE,
        payload: {
          error,
          pageNo: searchReqData.pageNo
        }
      });
    }
  };

export const getUserById = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: USERS.SEARCHING_USER_BY_ID
    });

    const { data: userData } = await api.user.byId(userId);

    return dispatch({
      type: USERS.SEARCH_USER_BY_ID_SUCCESS,
      payload: userData
    });
  } catch (error) {
    return dispatch({
      type: USERS.SEARCH_USER_BY_ID_FAILURE,
      payload: error
    });
  }
};

export const getUserCount = () => async (dispatch) => {
  try {
    const { data } = await api.user.count();

    return dispatch({
      type: USERS.GET_USER_COUNT_SUCCESS,
      payload: data.totalCount
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: USERS.GET_USER_COUNT_FAILURE
    });
  }
};

export const clearUsersList = () => ({
  type: USERS.CLEAR_USERS_LIST
});

export const clearUserDetails = () => ({
  type: USERS.CLEAR_USER_DETAILS
});

export const getUserOrders =
  ({ userId, pageNo, pageSize = 10 }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USERS.GETTING_USER_ORDERS
      });
      const { data } = await api.userOrders(userId).ordersV2(pageNo, pageSize);

      return dispatch({
        type: USERS.GET_USER_ORDERS_SUCCESS,
        payload: data
      });
    } catch (error) {
      return dispatch({
        type: USERS.GET_USER_ORDERS_FAILURE,
        payload: error
      });
    }
  };

export const clearUserOrders = () => ({
  type: USERS.CLEAR_USER_ORDERS
});

export const rejectUserKyc =
  ({ userId, username, formData, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USERS.REJECTING_USER_KYC
      });

      await api.user.rejectKyc(userId, formData);

      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: `Rejected  KYC for ${username}!`,
          status: 'success'
        })
      );

      dispatch({
        type: USERS.REJECT_USER_KYC_SUCCESS,
        payload: {
          note: formData.note
        }
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message || 'Failed to reject KYC!',
          status: 'error'
        })
      );
      dispatch({
        type: USERS.REJECT_USER_KYC_FAILURE
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const updateUserAddress =
  ({ userId, username, address, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USERS.UPDATING_USER_ADDRESS
      });
      const { data: updatedAddress } = await api.user.updateAddress(
        userId,
        address
      );

      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: `Updated address for user ${username}`,
          status: 'success'
        })
      );

      dispatch({
        type: USERS.UPDATE_USER_ADDRESS_SUCCESS,
        payload: updatedAddress
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: `Failed to updated address for ${username}`,
          status: 'error'
        })
      );
      dispatch({
        type: USERS.UPDATE_USER_ADDRESS_FAILURE
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const getUserReferralCode = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: USERS.GETTING_USER_REFERRAL_CODE
    });

    const { data } = await api.user.referralCode(userId);

    return dispatch({
      type: USERS.GET_USER_REFERRAL_CODE_SUCCESS,
      payload: data
    });
  } catch (error) {
    return dispatch({
      type: USERS.GET_USER_REFERRAL_CODE_FAILURE,
      payload: error
    });
  }
};

export const clearUserReferralCode = () => ({
  type: USERS.CLEAR_USER_REFERRAL_CODE
});

export const getGoldenTicketInfo = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: USERS.GETTING_GOLDEN_TICKET_INFO
    });
    const { data } = await api.user.getGoldenTicketInfo(userId);

    return dispatch({
      type: USERS.GET_GOLDEN_TICKET_INFO_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get golden tickets'
        }),
        status: 'error'
      })
    );
    return dispatch({
      type: USERS.GET_GOLDEN_TICKET_INFO_FAILURE,
      payload: error
    });
  }
};

export const clearGoldenTicketInfo = () => ({
  type: USERS.CLEAR_GOLDEN_TICKET_INFO
});

export const syncWallets =
  ({ userId, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USERS.SYNCING_WALLET
      });
      await api.referralsV2.syncWallet(userId);
      if (onSuccess) onSuccess();
      dispatch(getUserById(userId));
      dispatch(
        setAlert({
          title: 'Synced wallet for user!',
          status: 'success'
        })
      );

      return dispatch({
        type: USERS.SYNC_WALLET_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to sync user wallet'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: USERS.SYNC_WALLET_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const deleteUser =
  ({ userId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.user.deleteUser(userId);
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getUserBeneficiaries =
  ({ userId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.orders.getUserBeneficiaries({ userId });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getStudentProgramDetails =
  ({ userId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.studentProgram.getDetails(userId);
      onSuccess && onSuccess({ data });
    } catch (error) {
      if (error?.status === 400) {
        onSuccess && onSuccess({ data: null, message: error?.message });
        return;
      }
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateStudentProgramDetails =
  ({ payload, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.studentProgram.updateDetails(payload);
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateBeneficiaryV2 =
  ({ payload, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.orders.updateBeneficiaryV2({ payload });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const enableStudentKycFlow =
  ({ userId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.studentProgram.enableStudentKycFlow(userId);
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateVdaEligibilityStatus =
  ({ userId, newStatus, onComplete, onSuccess }) =>
  async (dispatch) => {
    try {
      await api.user.updateVdaEligibilityStatus({
        userId,
        status: newStatus
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateUserKycDetailsList =
  ({ details, userId, onComplete, onSuccess }) =>
  async (dispatch) => {
    try {
      await api.user.updateUserKycDetailsList({
        userId,
        details
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
