import dayjs from 'dayjs';
import {
  ALL_FUNNEL_KYC_STATUS,
  ALL_FUNNEL_ORDERS_STATUS,
  ALL_FUNNEL_V2_ORDERS_STATUS,
  ALL_LULU_ORDERS_FUNNEL_TABS,
  DASHBOARD_ACTIVE_SEARCH,
  FILTER_BY_FLAG,
  FILTER_BY_PAST_HOURS,
  JOB_FILTERS,
  NROB_ACTIVE_STEPS,
  NROB_FUNNEL_STATES,
  NROB_APPLICATION_STATES,
  NR_ACCOUNT_SEARCH_KEYS,
  VERIFICATIONS_ACTIVE_SEARCH
} from '../../constants';
import {
  getDefaultKycProviderForProduct,
  getDefaultProduct
} from '../../utils';
import { VDA_FUNNEL_STATES } from '../../views/Funnel/SaberFunnel/constants';
import { REQ_DATA as types } from '../constants';
const initialState = {
  dashboard: {
    users: {
      query: '',
      pageNo: 0,
      pageSize: 10,
      kycFilter: '',
      countryCode: ''
    },
    orders: {
      query: '',
      orderType: '',
      pageNo: 0,
      pageSize: 10
    },
    luluOrders: {
      query: '',
      pageNo: 0,
      pageSize: 10
    },
    activeSearch: DASHBOARD_ACTIVE_SEARCH.users
  },
  user: {
    orders: {
      remittance: {
        pageNo: 0,
        pageSize: 10
      },
      investments: {
        pageNo: 0,
        pageSize: 10
      },
      sips: {
        pageNo: 0,
        pageSize: 10
      }
    },
    referrals: {
      pageNo: 0,
      pageSize: 10
    }
  },
  funnel: {
    ordersV2: {
      acquirer: '',
      orderType: '',
      ordersInPastHours: FILTER_BY_PAST_HOURS[0],
      activeFunnel: ALL_FUNNEL_V2_ORDERS_STATUS[0],
      funnelReqData: ALL_FUNNEL_V2_ORDERS_STATUS.reduce(
        (reqData, currStatus) => ({
          ...reqData,
          [currStatus]: {
            pageNo: 0,
            pageSize: 10
          }
        }),
        {}
      )
    },
    orders: {
      activeFunnel: ALL_FUNNEL_ORDERS_STATUS[0],
      orders: ALL_FUNNEL_ORDERS_STATUS.reduce(
        (prev, status) => ({
          ...prev,
          [status]: {
            filterByFlag: FILTER_BY_FLAG.UNFILTERED,
            filtered: {
              pageNo: 0,
              pageSize: 10
            },
            unfiltered: {
              pageNo: 0,
              pageSize: 10
            }
          }
        }),
        {}
      )
    },
    luluOrders: {
      activeTab: ALL_LULU_ORDERS_FUNNEL_TABS[0],
      orders: ALL_LULU_ORDERS_FUNNEL_TABS.reduce((prev, tab) => {
        prev[tab] = {
          pageNo: 0,
          pageSize: 10
        };
        return prev;
      }, {})
    },
    kyc: {
      activeFunnel: ALL_FUNNEL_KYC_STATUS[0],
      users: ALL_FUNNEL_KYC_STATUS.reduce(
        (prev, status) => ({
          ...prev,
          [status]: {
            pageNo: 0,
            pageSize: 10
          }
        }),
        {}
      )
    },
    /* 
    {
      "page_no": 0,
      "page_size": 20,
      "query": null,
      "filters": [{
              "filter_key": "COUNTRY",
              "search_operation": "EQUALS",
              "values": [
                  "GB"
              ]
          }],
      "additional_filters": {
          "docs_pickup_pending_for_hours": null,
          "form_verification_pending_for_hours": null,
          "appointment_pending_for_hours": null
      }
    }
    */
    nronboard: {
      activeFunnel: Object.keys(NROB_ACTIVE_STEPS)[0],
      funnelSubState: '',
      country: '',
      initialized: false,
      reqData: Object.keys(NROB_ACTIVE_STEPS).reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: {
            query: null,
            pageNo: 0,
            pageSize: 20,
            subState: '',
            additionalFilters: null
          }
        };
      }, {})
    },
    vdaFunnel: {
      page: 1,
      pageSize: 10,
      status: VDA_FUNNEL_STATES.ON_RAMP_INITIATED,
      search: ''
    }
  },
  referral: {
    campaigns: {
      pageNo: 0,
      pageSize: 10,
      query: ''
    },
    v2: {
      redeemedCoins: {
        pageNo: 0,
        pageSize: 10,
        status: ''
      },
      userRedeemedCoins: {
        pageNo: 0,
        pageSize: 10
      }
    }
  },
  nrAccount: {
    users: {
      pageNo: 0,
      pageSize: 10,
      query: '',
      filterKey: NR_ACCOUNT_SEARCH_KEYS.ACCOUNT_STATUS.displayText,
      filterValue: ''
    }
  },
  providerHolidays: {
    holidays: {
      pageNo: 0,
      pageSize: 10
    }
  },
  kyc: {
    user: {
      docs: {
        pageNo: 0,
        pageSize: 10
      },
      checks: {
        pageNo: 0,
        pageSize: 10,
        provider: getDefaultKycProviderForProduct(getDefaultProduct()),
        product: getDefaultProduct()
      }
    },
    kycs: {
      pageNo: 0,
      pageSize: 10,
      provider: getDefaultKycProviderForProduct(getDefaultProduct()),
      product: getDefaultProduct(),
      activeTab: 0,
      activeSearch: VERIFICATIONS_ACTIVE_SEARCH.REMITTANCE,
      status: '',
      query: '',
      country: ''
    }
  },
  investments: {
    orders: {
      pageNo: 0,
      pageSize: 10,
      query: '',
      filterKey: '',
      filterValue: ''
    }
  },
  thunes: {
    orders: {
      pageNo: 0,
      pageSize: 10
    },
    fulfillments: {
      pageNo: 0,
      pageSize: 10,
      orderId: ''
    }
  },
  sourceAccount: {
    accounts: {
      pageNo: 0,
      pageSize: 10
    }
  },
  jobs: {
    pageNo: 0,
    pageSize: 10,
    filters: Object.keys(JOB_FILTERS)
      .map((filter) => ({
        key: JOB_FILTERS[filter].FILTER_KEY,
        value: ''
      }))
      .reduce(
        (prev, filter) => ({
          ...prev,
          [filter.key]: filter.value
        }),
        {}
      )
  },
  tickets: {
    query: '',
    pageNo: 0,
    pageSize: 10,
    status: '',
    assignedTo: ''
  },
  userSourceAccount: {
    page: 0,
    pageSize: 10
  },
  rda: {
    page: 1,
    pageSize: 10,
    active: false
  },
  vanceCash: {
    page: 1,
    pageSize: 10,
    totalPages: 0
  },
  uaeManualNonReconciled: {
    page: 0,
    pageSize: 10,
    totalPages: 0,
    orderId: '',
    isUpdatedByAgent: ''
  },
  pendingOrderReceipts: {
    pageNumber: 0,
    pageSize: 10,
    orderId: '',
    totalPages: 0,
    endDate: dayjs().format('YYYY-MM-DD'),
    startDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
    receiptStatus: 'REVIEW_PENDING'
  }
};

const reqDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.UPDATE_USERS_REQ_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          users: {
            ...state.dashboard.users,
            ...payload
          }
        }
      };

    case types.UPDATE_ORDERS_REQ_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          orders: {
            ...state.dashboard.orders,
            ...payload
          }
        }
      };

    case types.UPDATE_LULU_ORDERS_REQ_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          luluOrders: {
            ...state.dashboard.luluOrders,
            ...payload
          }
        }
      };

    case types.UPDATE_ACTIVE_SEARCH:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          activeSearch: payload
        }
      };

    case types.UPDATE_ACTIVE_ORDERS_FUNNEL:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          orders: {
            ...state.funnel.orders,
            activeFunnel: payload
          }
        }
      };

    case types.UPDATE_ACTIVE_LULU_ORDERS_TAB:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          luluOrders: {
            ...state.funnel.luluOrders,
            activeTab: payload
          }
        }
      };

    case types.UPDATE_ACTIVE_KYC_USERS_FUNNEL:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          kyc: {
            ...state.funnel.kyc,
            activeFunnel: payload
          }
        }
      };

    case types.UPDATE_FUNNEL_ORDERS_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          orders: {
            ...state.funnel.orders,
            orders: {
              ...state.funnel.orders.orders,
              [payload.status]: {
                ...state.funnel.orders.orders[payload.status],
                [state.funnel.orders.orders[payload.status].filterByFlag]: {
                  ...state.funnel.orders.orders[payload.status][
                    state.funnel.orders.orders[payload.status].filterByFlag
                  ],
                  ...payload.reqData
                }
              }
            }
          }
        }
      };

    case types.UPDATE_LULU_FUNNEL_ORDERS_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          luluOrders: {
            ...state.funnel.luluOrders,
            orders: {
              ...state.funnel.luluOrders.orders,
              [payload.tab]: {
                ...state.funnel.luluOrders.orders[payload.tab],
                ...payload.reqData
              }
            }
          }
        }
      };

    case types.UPDATE_FUNNEL_USERS_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          kyc: {
            ...state.funnel.kyc,
            users: {
              ...state.funnel.kyc.users,
              [payload.status]: {
                ...state.funnel.kyc.users[payload.status],
                ...payload.reqData
              }
            }
          }
        }
      };

    case types.UPDATE_FUNNEL_FLAGGED_FILTER:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          orders: {
            ...state.funnel.orders,
            orders: {
              ...state.funnel.orders.orders,
              [payload.status]: {
                ...state.funnel.orders.orders[payload.status],
                filterByFlag: payload.filterByFlag
              }
            }
          }
        }
      };

    case types.UPDATE_CAMPAIGNS_REQ_DATA:
      return {
        ...state,
        referral: {
          ...state.referral,
          campaigns: {
            ...state.referral.campaigns,
            ...payload
          }
        }
      };

    case types.UPDATE_NR_USERS_REQ_DATA:
      return {
        ...state,
        nrAccount: {
          ...state.nrAccount,
          users: {
            ...state.nrAccount.users,
            ...payload
          }
        }
      };

    case types.UPDATE_PROVIDER_HOLIDAYS_REQ_DATA:
      return {
        ...state,
        providerHolidays: {
          ...state.providerHolidays,
          holidays: {
            ...state.providerHolidays.holidays,
            ...payload
          }
        }
      };

    case types.UPDATE_KYC_DOCS_REQ_DATA:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          user: {
            ...state.kyc.user,
            docs: {
              ...state.kyc.user.docs,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_KYC_CHECKS_REQ_DATA:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          user: {
            ...state.kyc.user,
            checks: {
              ...state.kyc.user.checks,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_USER_KYC_REQ_DATA:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          user: {
            ...state.kyc.user,
            kyc: {
              ...state.kyc.user.kyc,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_ALL_KYCS_REQ_DATA:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          kycs: {
            ...state.kyc.kycs,
            ...payload
          }
        }
      };

    case types.UPDATE_INVESTMENT_ORDERS_REQ_DATA:
      return {
        ...state,
        investments: {
          ...state.investments,
          orders: {
            ...state.investments.orders,
            ...payload
          }
        }
      };

    case types.UPDATE_USER_TRANSFERS_REQ_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          orders: {
            ...state.user.orders,
            remittance: {
              ...state.user.orders.remittance,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_USER_REFERRAL_REQ_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          referrals: {
            ...state.user.referrals,
            ...payload
          }
        }
      };

    case types.UPDATE_USER_INVESTMENT_ORDERS_REQ_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          orders: {
            ...state.user.orders,
            investments: {
              ...state.user.orders.investments,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_USER_SIPS_REQ_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          orders: {
            ...state.user.orders,
            sips: {
              ...state.user.orders.sips,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_THUNES_ORDERS_REQ_DATA:
      return {
        ...state,
        thunes: {
          ...state.thunes,
          orders: {
            ...state.thunes.orders,
            ...payload
          }
        }
      };

    case types.UPDATE_THUNES_FULFILLMENTS_REQ_DATA:
      return {
        ...state,
        thunes: {
          ...state.thunes,
          fulfillments: {
            ...state.thunes.fulfillments,
            ...payload
          }
        }
      };

    case types.UPDATE_SOURCE_ACCOUNTS_REQ_DATA:
      return {
        ...state,
        sourceAccount: {
          ...state.sourceAccount,
          accounts: {
            ...state.sourceAccount.accounts,
            ...payload
          }
        }
      };

    case types.UPDATE_JOBS_REQ_DATA:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          ...payload
        }
      };

    case types.UPDATE_REDEEMED_COINS_REQ_DATA:
      return {
        ...state,
        referral: {
          ...state.referral,
          v2: {
            ...state.referral.v2,
            redeemedCoins: {
              ...state.referral.v2.redeemedCoins,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_USER_REDEEMED_COINS_REQ_DATA:
      return {
        ...state,
        referral: {
          ...state.referral,
          v2: {
            ...state.referral.v2,
            userRedeemedCoins: {
              ...state.referral.v2.userRedeemedCoins,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_FUNNEL_V2_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          ordersV2: {
            ...state.funnel.ordersV2,
            ...payload
          }
        }
      };

    case types.UPDATE_FUNNEL_V2_ORDERS_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          ordersV2: {
            ...state.funnel.ordersV2,
            funnelReqData: {
              ...state.funnel.ordersV2.funnelReqData,
              [payload.status]: {
                ...state.funnel.ordersV2.funnelReqData[payload.status],
                ...payload.reqData
              }
            }
          }
        }
      };

    case types.UPDATE_TICKETS_REQ_DATA:
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ...payload
        }
      };

    case types.UPDATE_NROB_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state?.funnel,
          nronboard: {
            ...state?.funnel?.nronboard,
            ...payload
          }
        }
      };

    case types.UPDATE_USER_SOURCE_ACCOUNTS_REQ_DATA:
      return {
        ...state,
        userSourceAccount: {
          ...state?.userSourceAccount,
          ...payload
        }
      };

    case types.UPDATE_RDA_REQ_DATA:
      return {
        ...state,
        rda: {
          ...state?.rda,
          ...payload
        }
      };

    case types.UPDATE_VANCE_CASH_REQ_DATA:
      return {
        ...state,
        vanceCash: {
          ...state?.vanceCash,
          ...payload
        }
      };

    case types.UPDATE_VDA_FUNNEL_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state?.funnel,
          vdaFunnel: {
            ...state?.funnel?.vdaFunnel,
            ...payload
          }
        }
      };

    case types.UPDATE_UAE_MANUAL_NON_RECONCILED:
      return {
        ...state,
        uaeManualNonReconciled: {
          ...state?.uaeManualNonReconciled,
          ...payload
        }
      };

    case types.UPDATE_PENDING_ORDER_RECEIPTS_REQ_DATA:
      return {
        ...state,
        pendingOrderReceipts: {
          ...state?.pendingOrderReceipts,
          ...payload
        }
      };

    default:
      return state;
  }
};

export default reqDataReducer;
