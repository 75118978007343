import {
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Box,
  VStack,
  HStack,
  Button,
  Select
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
// import { camelCaseToSpaceSeparated } from '../../utils';

const UpdateBeneficiartModal = ({
  isLoading,
  beneficiaryData,
  onSubmit,
  onCancel
}) => {
  const [beneficiary, setBeneficiary] = useState({});
  useEffect(() => {
    if (!beneficiaryData) return;
    setBeneficiary(
      // Object.keys(beneficiaryData)
      //   .filter(
      //     (key) => ['beneficiaryType'].findIndex((val) => val === key) !== -1
      //   )
      //   .reduce((prev, curr) => {
      //     return {
      //       ...prev,
      //       [curr]: beneficiaryData[curr]
      //     };
      //   }, {})
      {
        beneficiaryType: beneficiaryData?.beneficiaryType
      }
    );
  }, []);

  // const handleChange = (e) => {
  //   setBeneficiary((prev) => ({
  //     ...prev,
  //     [e?.target?.name]: e?.target?.value
  //   }));
  // };

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit && onSubmit(beneficiary);
  };

  return (
    <>
      <ModalHeader>Update Beneficiary</ModalHeader>
      <form method="POST" onSubmit={handleSubmit}>
        <ModalBody>
          {/* <VStack alignItems={'stretch'} width={'full'} gap={2}>
            {Object.keys(beneficiary)?.map((key) => (
              <Box key={key}>
                <FormLabel>{camelCaseToSpaceSeparated(key)}</FormLabel>
                <Input
                  name={key}
                  value={beneficiary[key]}
                  onChange={handleChange}
                />
              </Box>
            ))}
          </VStack> */}
          <Box>
            <FormLabel>Select Beneficiary Account Type</FormLabel>
            <Select
              value={beneficiary?.beneficiaryType}
              placeholder="Select Account Type"
              onChange={(e) => {
                setBeneficiary((prev) => ({
                  ...prev,
                  beneficiaryType: e?.target?.value
                }));
              }}
            >
              <option value={'SAVINGS'}>Savings</option>
              <option value={'NRE'}>NRE</option>
            </Select>
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'} gap={2}>
            <Button
              variant={'outline'}
              colorScheme="red"
              type="button"
              onClick={onCancel}
              isDisabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              variant={'outline'}
              colorScheme="brand"
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default UpdateBeneficiartModal;
