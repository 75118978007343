const RDA = {
  GETTING_ALL_POTS: 'GETTING_ALL_POTS',
  GET_ALL_POTS_SUCCESS: 'GET_ALL_POTS_SUCCESS',
  GET_ALL_POTS_FAILURE: 'GET_ALL_POTS_FAILURE',
  CREATING_POT: 'CREATING_POT',
  CREATE_POT_SUCCESS: 'CREATE_POT_SUCCESS',
  CREATE_POT_FAILURE: 'CREATE_POT_FAILURE',

  GET_ALL_POTS: 'GET_ALL_POTS',
  CREATE_POT: 'CREATE_POT',
  DELETE_POT: 'DELETE_POT',
  GET_FX_RATE: 'GET_FX_RATE',

  UPDATE_ACTIVE_ONLY: 'UPDATE_ACTIVE_ONLY'
};

export default RDA;
