import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  VStack,
  Text,
  Container,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  HStack,
  Tr,
  Select,
  Checkbox,
  Flex,
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBatchSheets, createBatch, downloadBatchSheet, uploadBatchSheet, createPending } from '../../store/actions/manualPayoutBatchActions';
import dayjs from 'dayjs';
import { setAlert } from '../../store/actions/alertActions';
import { Link } from 'react-router-dom';

const ManualPayoutBatch = () => {
  const dispatch = useDispatch();

  // Accessing Redux state
  const { sheets, pendingSheets, loading, creatingBatch, uploadingSheet } = useSelector((state) => state.manualPayoutBatch);
  const [payoutPartner, setPayoutPartner] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [selectedPayoutIds, setSelectedPayoutIds] = useState([]);
  const payoutPartners = ['ARMSTRONG'];
  const [selectAll, setSelectAll] = useState(false);

  // Fetch batch sheets when payout partner changes
  useEffect(() => {
    if (payoutPartner && orderStatus === 'CREATED') {
      dispatch(fetchBatchSheets(payoutPartner));
    } else if (payoutPartner && orderStatus === 'PENDING') {
      dispatch(createPending(payoutPartner));
    }
  }, [payoutPartner, orderStatus, dispatch]);
  
  // Handling batch creation
  const handleCreateBatch = () => {
    if (!payoutPartner) {
      dispatch(setAlert({ title: 'Please select a payout partner first', description: 'Please select a payout partner first', status: 'error' }));
      return;
    }

    if (orderStatus === 'PENDING' && selectedPayoutIds.length === 0) {
      dispatch(setAlert({ title: 'Please select at least one pending order', description: 'Please select at least one pending order to create a batch', status: 'error' }));
      return;
    }

    const payload = {
      payout_partner: payoutPartner,
      payout_ids: orderStatus === 'PENDING' ? selectedPayoutIds : []
    };

    dispatch(createBatch(payload));
  };

  // Handling CSV download
  const handleDownloadClick = (batchId) => {
    dispatch(downloadBatchSheet(batchId));
  };

  // Handling CSV upload
  const handleUploadClick = (batchId, event) => {
    const file = event.target.files[0];
    if (!file) return;

    dispatch(uploadBatchSheet(batchId, file));
  };

  // Format date function
  const formatDate = (dateString) => {
    return dayjs(dateString).format('MMM D, YYYY h:mm A');
  };

  // Handling payout partner change
  const handlePayoutPartnerChange = (e) => {
    setPayoutPartner(e.target.value);
    setOrderStatus('');
    setSelectedPayoutIds([]);
    setSelectAll(false);
  };

  // Handling order status change
  const handleOrderStatusChange = (e) => {
    setOrderStatus(e.target.value);
    setSelectedPayoutIds([]);
    setSelectAll(false);
  };

  // Handling pending order selection
  const handlePendingOrderSelect = (payoutId) => {
    setSelectedPayoutIds(prev => {
      const updatedSelection = prev.includes(payoutId)
        ? prev.filter(id => id !== payoutId)
        : [...prev, payoutId];
      
      setSelectAll(updatedSelection.length === sortedPendingSheets.length);
      return updatedSelection;
    });
  };

  // Handling select all functionality
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedPayoutIds(sortedPendingSheets.map(sheet => sheet.payoutId));
    } else {
      setSelectedPayoutIds([]);
    }
  };
  
  //Sort by desc wrt INR
  const sortedPendingSheets = (pendingSheets || []).slice().sort((a, b) => b.inr_amt - a.inr_amt);

  return (
    <ContentLayout>
      <VStack spacing={4} align="start">
        <Container maxW="container.md" py={10} bg="black" color="white">
          <Box mb={6}>
            <Heading size="lg" color="green.400">
              Manual Payout Batch Process
            </Heading>
            <Text fontSize="md" color="gray.400">
              Manage and download your payout batches efficiently.
            </Text>
          </Box>
          <HStack spacing={4} mb={4}>
            <Select
              placeholder="Select Payout Partner"
              bg="gray.800"
              borderColor="green.400"
              color="white"
              size="md"
              _hover={{ bg: "gray.700" }}
              _focus={{ borderColor: "green.400", boxShadow: "0 0 0 1px green.400" }}
              value={payoutPartner}
              onChange={handlePayoutPartnerChange}
            >
              {payoutPartners.map((partner) => (
                <option key={partner} value={partner}>
                  {partner}
                </option>
              ))}
            </Select>
            <Select
              placeholder="Filter orders"
              bg="black"
              color="gray"
              size={'md'}
              maxWidth="150px"
              rounded={'lg'}
              _focus={{
                border: '1px solid #81EBAB',
                boxShadow: 'none'
              }}
              value={orderStatus}
              onChange={handleOrderStatusChange}
            >
              <option value="CREATED">Created</option>
              <option value="PENDING">Pending</option>
            </Select>
          </HStack>
          
          {loading ? (
            <Text color="white">Loading...</Text>
          ) : orderStatus === 'PENDING' ? (
            <>
              <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Text color="white">
                  Selected Orders: {selectedPayoutIds.length} / {sortedPendingSheets.length}
                </Text>
                <Button
                  variant={'outline'}
                  colorScheme="brand"
                  size="md"
                  px={'5'}
                  onClick={handleCreateBatch}
                  isDisabled={selectedPayoutIds.length === 0}
                  isLoading={creatingBatch}
                  loadingText="Creating"
                >
                  CREATE BATCH
                </Button>
              </Flex>
              <Table variant="simple" colorScheme="whiteAlpha">
                <Thead>
                  <Tr>
                  <Th color="gray.400">
                    <HStack spacing="1rem">
                      <Checkbox
                        isChecked={selectAll}
                        onChange={handleSelectAll}
                        colorScheme="gray"
                      />
                      <Text>Select All</Text>
                    </HStack>
                  </Th>
                    <Th color="gray.400">Order ID</Th>
                    <Th color="gray.400">Payout ID</Th>
                    <Th color="gray.400">Amount (INR)</Th>
                    <Th color="gray.400">Created At</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Array.isArray(sortedPendingSheets) && sortedPendingSheets.length > 0 ? (
                    sortedPendingSheets.map((entry) => (
                      <Tr key={entry.payoutId}>
                        <Td>
                          <Checkbox
                            isChecked={selectedPayoutIds.includes(entry.payoutId)}
                            onChange={() => handlePendingOrderSelect(entry.payoutId)}
                          />
                        </Td>
                        <Td color="white">{entry.orderId}</Td>
                        <Td color="white">{entry.payoutId}</Td>
                        <Td color="white">{entry.inrAmt ? entry.inrAmt.toFixed(2) : 'N/A'}</Td>
                        <Td color="white">{entry.createdAt ? formatDate(entry.createdAt) : 'N/A'}</Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={5} color="white">No pending sheets available</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </>
          ) : orderStatus === 'CREATED' ? (
            <Table variant="simple" colorScheme="whiteAlpha">
              <Thead>
                <Tr>
                  <Th color="gray.400">Batch ID</Th>
                  <Th color="gray.400">Status</Th>
                  <Th color="gray.400">Created At</Th>
                  <Th color="gray.400">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Array.isArray(sheets) && sheets.length > 0 ? (
                  sheets.map((sheet) => (
                    <Tr key={sheet.batchId}>
                      <Td color="white">
                        <Link to={`/manual-payouts/batch/${sheet.batchId}`} style={{ color: 'lightblue', textDecoration: 'underline' }}>
                          {sheet.batchId || 'N/A'}
                        </Link>
                      </Td>
                      <Td color="white">{sheet.status || 'N/A'}</Td>
                      <Td color="white">{sheet.createdAt ? formatDate(sheet.createdAt) : 'N/A'}</Td>
                      <Td>
                        <HStack spacing={2}>
                          <Button
                            colorScheme="green"
                            onClick={() => handleDownloadClick(sheet.batchId)}
                          >
                            Download
                          </Button>
                          {(sheet.status === 'CREATED' || sheet.status === 'PROCESSING') && (
                            <Button
                              as="label"
                              htmlFor={`file-upload-${sheet.batchId}`}
                              colorScheme="whiteAlpha"
                              cursor="pointer"
                              isLoading={uploadingSheet === sheet.batchId}
                              loadingText="Uploading"
                            >
                              {uploadingSheet === sheet.batchId ? 'Uploading' : 'Upload'}
                              <input
                                id={`file-upload-${sheet.batchId}`}
                                type="file"
                                accept=".csv"
                                style={{ display: 'none' }}
                                onChange={(e) => handleUploadClick(sheet.batchId, e)}
                              />
                            </Button>
                          )}
                        </HStack>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={4} color="white">No sheets available</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          ) : (
            <Text color="white">Please select a payout partner and order status to view data.</Text>
          )}
        </Container>
      </VStack>
    </ContentLayout>
  );
};

export default ManualPayoutBatch;