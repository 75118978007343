import RDA from '../constants/rdaConstants';

const initialState = {
  isError: false,
  isLoading: false,
  error: null,
  totalPages: 0,
  activeOnly: true,
  data: []
};

const rdaReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RDA.CREATE_POT_SUCCESS:
      return {
        ...state,
        data: [payload, ...state?.data]
      };
    case RDA.GETTING_ALL_POTS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null,
        totalPages: 0,
        data: []
      };
    case RDA.GET_ALL_POTS_SUCCESS:
      return {
        ...state,
        data: payload?.data,
        isLoading: false,
        isError: false,
        error: null,
        totalPages: payload?.totalPages
      };

    case RDA.DELETE_POT_SUCCESS:
      return state;

    case RDA.UPDATE_ACTIVE_ONLY:
      return {
        ...state,
        activeOnly: !state?.activeOnly
      };

    default:
      return state;
  }
};

export default rdaReducer;
