import {
  Heading,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  Text,
  useDisclosure,
  Spinner,
  IconButton,
  Grid,
  VStack
} from '@chakra-ui/react';
import ContentLayout from '../../../layouts/ContentLayout';
import { useEffect, useState } from 'react';
import { RDA_PROVIDERS } from '../../../constants';
import ModalLayout from '../../../components/Modal/ModalLayout';
import UpdateRdaProviderModal from '../../../components/Rda/modal/UpdateRdaProvider';
import { useDispatch } from 'react-redux';
import {
  getRdaProvider,
  updateRdaProviderStatus
} from '../../../store/actions/rdaActions';
import { tableCellStyles } from '../../../components/NrOnboard/constants';
import { MdEdit } from 'react-icons/md';
import { kebabCaseToSpaceSeperate } from '../../../utils';

const RdaProvider = () => {
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedProvider, setSelectedProvider] = useState(null);
  const dispatch = useDispatch();

  const getProviderData = () => {
    setIsLoading(true);
    dispatch(
      getRdaProvider({
        providers: Object.keys(RDA_PROVIDERS),
        onSuccess: (data) => setProviders(data),
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    getProviderData();
  }, []);

  const handleUpdateCancel = () => {
    setSelectedProvider(null);
    onClose && onClose();
  };

  const handleUpdateSubmit = ({ provider, reason, status }) => {
    setIsLoading(true);
    dispatch(
      updateRdaProviderStatus({
        provider: selectedProvider.provider,
        status,
        reason,
        onSuccess: () => {
          getProviderData();
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  const cellStyles = tableCellStyles();

  return (
    <>
      <ModalLayout isOpen={isOpen} onClose={handleUpdateCancel}>
        <UpdateRdaProviderModal
          isLoading={isLoading}
          onCancel={handleUpdateCancel}
          onSubmit={handleUpdateSubmit}
          provider={selectedProvider}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            gap={'30px'}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
          >
            <Heading color={'white'} size={'md'}>
              RDA Provider
            </Heading>
            {isLoading && <Spinner />}
            {!isLoading && providers.length === 0 && (
              <Text color={'white'} fontSize={'md'}>
                No Provider data found!!
              </Text>
            )}
            {!isLoading && providers.length > 0 && (
              <Table>
                <Thead>
                  <Tr>
                    <Td style={cellStyles}>
                      <Text fontSize={'sm'} color={'gray.400'}>
                        Provider
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text fontSize={'sm'} color={'gray.400'}>
                        Status
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text fontSize={'sm'} color={'gray.400'}>
                        Edit
                      </Text>
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {providers?.map((provider) => (
                    <Tr>
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {kebabCaseToSpaceSeperate(provider?.provider)}
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {provider?.status}
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <IconButton
                          variant={'unstyled'}
                          icon={<MdEdit color="gray" />}
                          onClick={() => {
                            setSelectedProvider(provider);
                            onOpen();
                          }}
                        ></IconButton>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default RdaProvider;
