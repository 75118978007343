import { combineReducers } from 'redux';
import alertReducer from './alertReducer';
import authReducer from './authReducer';
import funnelReducer from './funnelReducer';
import nrAccountReducer from './nrAccountReducer';
import ordersReducer from './ordersReducers';
import referralReducer from './referralReducer';
import reqDataReducer from './reqDataReducer';
import resDataReducer from './resDataReducer';
import usersReducer from './usersReducer';
import exchangeReducer from './exchangeReducer';
import providerHolidaysReducer from './providerHolidaysReducer';
import workflowReducer from './workflowReducer';
import kycReducer from './kycReducer';
import investmentReducer from './investmentReducer';
import thunesReducer from './thunesReducer';
import sourceAccountReducer from './sourceAccountReducer';
import jobsReducer from './jobsReducer';
import configReducer from './configReducer';
import userReferralReducer from './userReferralReducer';
import nreNroReducer from './nreNroReducer';
import ticketsReducer from './ticketsReducer';
import cxUsersReducer from './cxUsersReducer';
import workflowReducerV2 from './workflowReducerV2';
import nronboardReducer from './nronboardreducer';
import yblDetailsReducer from './yblReducer';
import orderScreenConfigReducer from './orderScreenConfigReducer';
import rdaReducer from './rdaReducer';
import manualPayoutBatchReducer from './manualPayoutBatchReducer';

const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  users: usersReducer,
  orders: ordersReducer,
  reqData: reqDataReducer,
  resData: resDataReducer,
  funnel: funnelReducer,
  referral: referralReducer,
  nrAccount: nrAccountReducer,
  exchange: exchangeReducer,
  providerHolidays: providerHolidaysReducer,
  workflow: workflowReducer,
  kyc: kycReducer,
  investments: investmentReducer,
  thunes: thunesReducer,
  sourceAccount: sourceAccountReducer,
  jobs: jobsReducer,
  config: configReducer,
  userReferral: userReferralReducer,
  nreNro: nreNroReducer,
  tickets: ticketsReducer,
  cxUsers: cxUsersReducer,
  workflowsV2: workflowReducerV2,
  nronboard: nronboardReducer,
  yblDetails: yblDetailsReducer,
  orderScreenConfig: orderScreenConfigReducer,
  rda: rdaReducer,
  manualPayoutBatch: manualPayoutBatchReducer,

});

export default rootReducer;
