import {
  Button,
  Stack,
  StackDivider,
  VStack,
  Text,
  Flex,
  Link,
  HStack
} from '@chakra-ui/react';
import React from 'react';
import { MdArrowForward } from 'react-icons/md';
import { Link as ReactLink } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import useIsAdmin from '../../hooks/useIsAdmin';

const sidebarItems = {
  nr_onboarding: {
    title: 'NR Onboarding',
    link: '/funnel/nr-onboarding',
    isAdminOnly: false
  },
  active_orders: {
    title: 'Active Orders',
    link: '/funnel/orders',
    isAdminOnly: false
  },
  active_lulu_orders: {
    title: 'Active Lulu Orders',
    link: '/funnel/orders/lulu',
    isAdminOnly: false
  },
  campaigns: {
    title: 'Campaigns',
    link: '/campaign',
    isAdminOnly: false
  },
  verifications: {
    title: 'Verifications',
    link: '/kyc',
    isAdminOnly: false
  },
  on_hold_fulfillments: {
    title: 'On Hold Fulfillments',
    link: '/thunes/fulfillments',
    isAdminOnly: false
  },
  jobs: {
    title: 'Jobs',
    link: '/jobs',
    isAdminOnly: false
  },
  config: {
    title: 'Config',
    link: '/config',
    isAdminOnly: true
  }
};

const Sidebar = ({
  user,
  userCount,
  onLogout,
  exchangeRate,
  onExploreClick
}) => {
  const isAdmin = useIsAdmin();

  return (
    <VStack
      divider={<StackDivider borderColor="whiteAlpha.300" />}
      maxHeight={{ base: 'none', lg: '100vh' }}
      pb={{ base: 2, lg: 0 }}
    >
      <Stack h={{ base: 'auto', lg: '60vh' }} w="100%">
        <Flex
          paddingTop={{ base: 2, lg: '10vh' }}
          pl={{ base: 8, lg: 5 }}
          pb={{ base: 4, lg: 0 }}
          w="100%"
        >
          <VStack gap={1.5}>
            {' '}
            {Object.keys(sidebarItems).map(
              (itemId) =>
                (!sidebarItems[itemId].isAdminOnly || isAdmin) && (
                  <Link
                    key={itemId}
                    as={ReactLink}
                    to={sidebarItems[itemId].link}
                    color="brand.500"
                    justifyContent={'flex-start'}
                    w={'100%'}
                  >
                    <HStack>
                      <Text>{sidebarItems[itemId].title}</Text>
                      <BsArrowRight display={'inline'} />
                    </HStack>
                  </Link>
                )
            )}
            <Button
              width={'full'}
              variant={'outline'}
              colorScheme="brand"
              onClick={onExploreClick}
            >
              Explore
            </Button>
          </VStack>
        </Flex>
      </Stack>
      <VStack alignSelf="start" px="8">
        <HStack>
          <Text alignSelf="start" fontSize="md" color="gray">
            Market Rate:
          </Text>
          <Text alignSelf="flex-start" color="brand.500">
            {exchangeRate}
          </Text>
        </HStack>
      </VStack>
      <VStack alignSelf="start" px="8">
        <VStack>
          <Text alignSelf="start" fontSize="md" color="gray">
            Total Users:
          </Text>
          <Text fontSize="4xl" alignSelf="flex-start" color="brand.500">
            {userCount}
          </Text>
        </VStack>
      </VStack>
      <VStack alignSelf="start" px="8">
        <VStack paddingEnd={20}>
          <Text alignSelf="start" fontSize="md" color="gray">
            Signed in:
          </Text>
          <Text fontSize="2xl" alignSelf="flex-start" color="white">
            {user.name}
          </Text>
          <Button
            variant="outline"
            type="logout"
            color="gray"
            rounded="lg"
            onClick={onLogout}
            fontWeight={600}
            alignSelf="start"
            px={5}
            rightIcon={<MdArrowForward size="1.5rem" />}
          >
            Logout
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Sidebar;
