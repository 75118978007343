import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';

const CreatePot = ({
  isLoading = false,
  onCancel = () => {},
  onSubmit = (data) => {}
}) => {
  const [amount, setAmount] = useState(0);

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit && onSubmit({ amount });
  };

  return (
    <>
      <ModalHeader>Create Pot</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <VStack alignItems={'stretch'} gap={2}>
            <Box>
              <FormLabel>Amount</FormLabel>
              <Input
                type="number"
                onChange={(e) => setAmount(e?.target?.value)}
              />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'} gap={2}>
            <Button
              type="button"
              variant={'outline'}
              colorScheme="red"
              onClick={onCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant={'outline'}
              colorScheme="brand"
              isLoading={isLoading}
            >
              Create
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default CreatePot;
